<template>
  <div class="d-flex flex-row flex-wrap" id="signIn" style="min-height: 100vh;">
    <div
      class="d-flex flex-column flex-wrap login__left"
      style="flex-grow: 3;"
      :style="{ background: `url(${bg})`, backgroundSize: 'cover' }"
    >
      <div class="d-flex flex-row justify-center  flex-grow-1 backdrop mt-9">
        <h3 class="white--text">Shell Client Side Project</h3>
      </div>
    </div>
    <div
      class="d-flex flex-column flex-wrap flex-shrink-0 align-content-center justify-center"
      style="flex-grow: 1"
    >
      <div class="d-flex flex-row mt-n6 ">
        <h3
          class="pl-4 pt-1 primary--text font-weight-bold font-italic ft text-xl logo-shadow"
        >
          Reset your password
        </h3>
      </div>
      <v-card
        flat
        :loading="isLoading"
        id="loginCard"
        class="rounded-sm ma-3 pl-4 pr-4 pb-6 mt-n12"
      >
        <div class="signin mx-2 mb-5 mt-4 ">
          <div class="ft text-md d-flex flex-row justify-center pt-3 pl-1">
            <h5>Reset Password</h5>
          </div>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(reset)">
            <ValidationProvider
              name="email"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="d-flex flex-row flex-grow-1 flex-shrink-1">
                <v-text-field
                  outlined
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  class="ft mt-2"
                  :type="show ? 'text' : 'password'"
                  ref="password"
                  @click:append="show = !show"
                  label="Password"
                  v-model="newPassword"
                  :error-messages="errors[0]"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="d-flex flex-row flex-grow-1 flex-shrink-1">
                <v-text-field
                  outlined
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  class="ft mt-2"
                  :type="show ? 'text' : 'password'"
                  ref="password"
                  @click:append="show = !show"
                  label="Password"
                  v-model="confirmPassword"
                  :error-messages="errors[0]"
                />
              </div>
            </ValidationProvider>
            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-btn
                tag="a"
                small
                @click="state === true"
                color="primary"
                class="mt-n1 text-sm ft text-capitalize"
                text
                >Forgot Password?
              </v-btn>
            </div>
            <v-card-actions class="justify-end mt-1 pl-0 pr-0 pb-2">
              <v-btn
                type="submit"
                :disabled="isLoading"
                color="primary"
                block
                @click="$router.push({ name: 'master.login' })"
                class="ft text-sm text-capitalize"
                >Login
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </div>

    <v-snackbar v-model="showSnackBar" :timeout="4000" top color="error">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import * as axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
// Add the required rule
extend("required", {
  ...required,
  message: "This field is required"
});

// Add the email rule
extend("email", {
  ...email,
  message: "This field must be a valid email"
});

export default {
  name: "Login",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      email: "",
      state: false,
      showSnackBar: false,
      bg: require("@/assets/shell.jpeg"),
      newPassword: "",
      confirmPassword: "",
      load: false,
      timeout: 4000,
      message: "",
      closeSnackBar: false,

      error: "",
      loader: false,
      snackbar: false
    };
  },
  methods: {
    reset() {
      if (this.email !== "" && this.password !== "") {
        this.load = true;
        setTimeout(() => {
          axios({
            method: "post",
            url: `${process.env.VUE_APP_BASE}/entry/login`,
            data: { email: this.email, password: this.password }
          })
            .then(response => {
              console.log(response);
            })
            .catch(err => {
              console.log(err);
            });
        }, 1000);
      }
    }
  }
};
</script>
<style scoped></style>
